<template>
    <div class="products-all">
		<!-- <div class="common-title products-title">Products</div> -->
		<Examples :headTop="headTop" :isFullHeader="false" :tabs="examplesDatas" />
    </div>
</template>


<script>
    import '@/assets/less/Products.less';
	import Examples from '@/components/Examples.vue';

	export default {
        name: 'Products',
        props: [
			'headTop'
		],
		components: {
			Examples
		},
		data() {
			return {
				examplesDatas: [
                            {id: 1, title:"3ply Masks", imgs: {
                                isOnlyImage: true,
                                images: [
                                    require('@/assets/images/products/3plyMasks/1.png'),
                                    require('@/assets/images/products/3plyMasks/2.png'),
                                ]
                            }},
                            {id: 2, title:"FFP2", imgs: {
                                isOnlyImage: true,
                                images: [
                                    require('@/assets/images/products/FFP2/1.png'),
                                    require('@/assets/images/products/FFP2/2.png'),
                                    require('@/assets/images/products/FFP2/3.png'),
                                    require('@/assets/images/products/FFP2/4.png'),
                                ]
                            }},
                            {id: 3, title:"Visor Masks", imgs: {
                                isOnlyImage: true,
                                images: [
                                    require('@/assets/images/products/VisorMasks/1.png'),
                                ]
                            }}
                    ]
			}
		}
}
</script>